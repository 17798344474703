
import { defineComponent, nextTick, ref } from 'vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { getErrorMsg, swalErrNotification, swalNotification } from '@/core/helpers/utils';
import { useCreatingForm } from '@/core/hooks/use-creating-form';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { ICapSuaChuaResponseBase, IDanhMucToaXeResponseBase, IToaXeResponseBase } from '../../../../core/interfaces/ApiResponses';
import { useLoading } from '@/core/hooks/use-loading';
import { KeHoachSuaChuaService } from '@/core/services/KeHoachSuaChua.service';
import { CapSuaChuaService } from '@/core/services/CapSuaChuaService.service';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';
import ButtonCustom from '../../../../components/shared/buttons/ButtonCustom.vue';
import DivRow from '../../../../layout/_shared/DivRow.vue';
import DivCol from '../../../../layout/_shared/DivCol.vue';
import DanhMucToaXeService from '../../../../core/services/DanhMucToaXe.service';
import LabelCustom from '../../../../components/shared/label-custom/LabelCustom.vue';
import ResponsiveLayout from '@/layout/_shared/ResponsiveLayout.vue';

export default defineComponent({
  name: 'ke-hoach-sua-chua-form',

  components: {
    ButtonCustom,
    DivCol,
    DivRow,
    LabelCustom,
    PageRowLayout,
    ResponsiveLayout,
  },

  setup() {
    const { isCreating, formRef } = useCreatingForm();
    const { goBack, replace, id } = useRouterCustom();
    const { startLoading, endLoading } = useLoading();
    const { ButtonTypeColors, ButtonsType } = useButtonCustom();

    const modalThemToaXeRef = ref();

    return {
      isCreating,
      idKeHoach: id,
      goBack, replace, formRef,
      modalThemToaXeRef,
      startLoading, endLoading,
      ButtonTypeColors, ButtonsType,
    }
  },

  data() {
    return {
      toaXeSelectedItems: [] as IToaXeResponseBase[],
      danhMucToaXeItems: [] as IDanhMucToaXeResponseBase[],
      danhMucToaXeId: 0,
      isDanhMucItemsAvailable: true,
      tenDanhMucToaXe: '',
      capSuaChuaItems: [] as ICapSuaChuaResponseBase[],
      capSuaChuaId: 0,
      rules: {
        ten_ke_hoach: [
          {
            required: true,
            message: "Phải nhập tên kế hoạch",
            trigger: "blur",
          }
        ]
      },

      form: {
        ten_ke_hoach: '',
        thang: 1,
        so_luong_toa_xe: 0,
        ghi_chu: '',
      },
    }
  },

  async mounted() {
    this.startLoading()
    setCurrentPageTitle('Kế hoạch sửa chữa');

    const { data: { data: { data } } } = await DanhMucToaXeService.list();
    const { data: { data: { data: capSuaChuaResponse } } } = await CapSuaChuaService.list({});
    this.capSuaChuaItems = capSuaChuaResponse;
    this.danhMucToaXeItems = data;

    if (!this.isCreating) {
      await this.fetchKeHoach();
    }

    this.endLoading();
  },

  methods: {
    async fetchKeHoach() {
      try {
        const {
          data: {
            data: {
              ten_ke_hoach,
              thang,
              ghi_chu,
              danh_muc_toa_xe: {
                ten_danh_muc: tenDanhMucToaXe,
                id: idDanhMucToaXe,
              },
              so_luong_toa_xe,
              cap_sua_chua: {
                id: idCapSuaChua,
              }
            }
          }
        } = await KeHoachSuaChuaService.get(this.idKeHoach);

        this.form.ten_ke_hoach = ten_ke_hoach;
        this.form.thang = thang;
        this.form.ghi_chu = ghi_chu;
        this.form.so_luong_toa_xe = so_luong_toa_xe;
        this.tenDanhMucToaXe = tenDanhMucToaXe;
        this.danhMucToaXeId = idDanhMucToaXe;
        this.capSuaChuaId = idCapSuaChua
      } catch (e) {
        this.endLoading();
        swalErrNotification(e, 'Có lỗi khi lấy thông tin kế hoạch sửa chữa');
      }
    },

    onAddToaXeClickedHandler() {
      nextTick(() => {
        this.modalThemToaXeRef.isOpen = true;
      });
    },

    clear() {
      this.form.ghi_chu = '';
      this.form.ten_ke_hoach = '';
      this.danhMucToaXeId = 0;
      this.capSuaChuaId = 0;
    },

    async queryDanhMucToaXe(queryString: string, fn) {
			const { data: { data: { data } } } = await DanhMucToaXeService.list(
        1,
        1000,
        queryString,
      );

			if (data.length === 0 && queryString) {
				this.isDanhMucItemsAvailable = false;
			} else if (data.length >= 0) {
				this.isDanhMucItemsAvailable = true;
			}

			fn(data);
		},

    onSelectDanhMucToaXe({ id }) {
			this.danhMucToaXeId = id;
		},

    async submit() {
      if (!this.formRef) {
				return;
			}

      if (!this.danhMucToaXeId) {
        swalNotification(
          'Chưa chọn danh mục toa xe',
          'error'
        );
        return;
      }

      if (!this.capSuaChuaId) {
        swalNotification(
          'Chưa chọn cấp sửa chữa',
          'error'
        );
        return;
      }

      this.formRef.validate(async (valid) => {
        if (valid) {
          try {
            const {
              ten_ke_hoach,
              ghi_chu,
              thang,
              so_luong_toa_xe,
            } = this.form;
            const { capSuaChuaId: id_cap_sc, danhMucToaXeId: id_danh_muc_toa_xe } = this;
            this.startLoading();

            const { data: { data: { id: idKeHoach } } } = await KeHoachSuaChuaService.createOrUpdate({
              ten_ke_hoach,
              ghi_chu,
              thang,
              id_cap_sc,
              id_danh_muc_toa_xe,
              so_luong_toa_xe,
              isCreating: this.isCreating,
              ...(!this.isCreating && {
                id_ke_hoach: this.idKeHoach,
              }),
            });

            this.idKeHoach = idKeHoach;
            this.endLoading();

            await swalNotification(
              `${this.isCreating ? 'Tạo mới' : 'Chỉnh sửa'} kế hoạch thành công`,
              'success',
            );
            this.replace(`/quan-ly/ke-hoach-sua-chua/chi-tiet/${this.idKeHoach}`);
          } catch(e) {
            this.endLoading();
            await swalNotification(
              getErrorMsg(e, `Có lỗi khi ${this.isCreating ? 'tạo mới' : 'chỉnh sửa'} kế hoạch`),
              'error',
            );
          }
        } else {
          return false;
        }
      });
    }
  }
})
